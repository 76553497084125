
    import Radar from '~/node_modules/radar-sdk-js'
    import 'radar-sdk-js/dist/radar.css'
    import maplibregl from 'maplibre-gl'
    import moment from 'moment'
    
    import { mapState, mapActions, mapMutations } from 'vuex'
  
    export default {
      data() {
        return {
          map: false,
          activeLocation: null,
          markersAdded: false,
        }
      },
      mounted() {
        const radarKey = process.env.RADAR_KEY
        if (!radarKey) {
          this.$utils.log('RadarMap - RADAR_KEY is missing')
          return
        }
  
        setTimeout(() => {
          this.initialize()
  
          if (this.location) {
            this.addMarker()
          } else if (this.appRadar['add_markers_on_initialize']) {
            // add all markers by default
            this.addMarkers(this.locations, true, false)
          }
          
          this.markersAdded = true
  
          this.$nextTick(() => {
            document.addEventListener('click', function(e) {
              if (e.target.hasAttribute('data-order')) {
                this.startOrder(this.activeLocation)
              }
              else if (e.target.hasAttribute('data-reservation')) {
                this.goToReserveTable()
              }
              else if (e.target.innerText === 'ANNEX') {
                this.goToAnnexOrder()
              }
            }.bind(this))
          })
        }, 200)
      },
      computed: {
        ...mapState([ 'appInfo', 'cart', 'location', 'geolocation' ]),
        ...mapState({
          all: state => state.locations.all,
          results: state => state.locations.results,
          handoffMode: state => state.order.handoffMode,
        }),
        locations() {
          if (this.results && this.results.length) {
            return this.results
          } else {
            return this.all.reduce((acc, state) => {
              state.cities.forEach((city) => {
                city.locations.forEach((location) => acc.push(location))
              })
  
              return acc
            }, [])
          }
        },
        popup() {
          if (document.querySelector('.radar-map-popup')) {
            return document.querySelector('.radar-map-popup').innerHTML
          }
        },
        appRadar() {
          return this.appInfo['radar_map']
        },
        orderHere() {
          return 'Order Here'
          // TODO: the handoffMode may not be in sync with the store here
        },
        locationDisabled() {
          switch(this.handoffMode) {
            case 'pickup':
            case 'delivery':
            case 'curbside':
            case 'drivethru':
            case 'ordering':
              return !this.activeLocation.services[this.handoffMode]
            default:
              return false
          }
        },
        hasReservation() {
          return this.appRadar?.enable_reservation
        },
        isMobile() {
          return this.$mq === 'sm' || this.$mq === 'md'
        },
        viewDetails() {
          if (this.appRadar?.view_details_cta_text) {
            return this.appRadar.view_details_cta_text
          }
  
          return 'View Details'
        },
      },
      methods: {
        ...mapActions([ 'trackEvent', 'sendRequest', 'changeLocation' ]),
        ...mapMutations({
          setHandoffMode: 'order/setHandoffMode',
        }),
        ...mapMutations([ 'setLocation', 'setErrors', 'setLoading', 'setMenu' ]),
        initialize() {
          const radarKey = process.env.RADAR_KEY
  
          Radar.initialize(radarKey)
          // prevent duplicate maps from rendering over each other
          if (this.map) {
            this.map.remove()
          }
          this.map = Radar.ui.map({ container: 'map' })
          this.map.setStyle(this.appRadar.style)
          this.map.on('error', (err) => {
            console.error(err)
          })
        },
        zoomInLocation() {
          this.map?.setZoom(this.appRadar['single_result_zoom'])
          setTimeout(() => this.map.setCenter([this.location.lng, this.location.lat]), 250)
        },
        addMarker() {
          const marker = document.createElement('div')
          marker.className = 'marker'
          marker.style.backgroundImage = 'url('+this.appRadar.marker.url+')'
          marker.style.width = this.appRadar.marker.width
          marker.style.height = this.appRadar.marker.height
          marker.style.backgroundSize = this.appRadar.marker['background_size']
          marker.style.backgroundRepeat = this.appRadar.marker['background_repeat']
          marker.addEventListener('click', () => {
            this.setActiveLocation(this.location)
          })
          new maplibregl.Marker(marker)
            .setLngLat([this.location.lng, this.location.lat])
            .addTo(this.map)
          this.zoomInLocation()
        },
        addMarkers(locationsList, duringMount = false, checkNoResults = false) {
          locationsList.forEach((location, i) => {
            const marker = document.createElement('div')
            marker.className = 'marker'
            marker.style.backgroundImage = 'url('+this.appRadar.marker.url+')'
            marker.style.width = this.appRadar.marker.width
            marker.style.height = this.appRadar.marker.height
            marker.style.backgroundSize = this.appRadar.marker['background_size']
            marker.style.backgroundRepeat = this.appRadar.marker['background_repeat']
            marker.addEventListener('click', () => {
              this.setActiveLocation(location)
            })
  
            if (!location.key) { // all locations
              const popup = new maplibregl.Popup({ offset: this.appRadar.offset })
                .setLngLat([location.lng, location.lat])
                .setHTML(this.popup)
                .addTo(this.map)
              new maplibregl.Marker(marker)
                .setLngLat([location.lng, location.lat])
                .setPopup(popup)
                .addTo(this.map)
            } else { // search results
              new maplibregl.Marker(marker)
                .setLngLat([location.lng, location.lat])
                .addTo(this.map)
            }
  
            // center the map to the first result on search
            if (duringMount && !this.location) {
              if (this.geolocation) {
                this.map?.setZoom(this.appRadar['single_result_zoom'])
                setTimeout(() => this.map.setCenter([this.geolocation.lng, this.geolocation.lat]), 250)
              } else {
                this.map?.setZoom(this.appRadar['initial_zoom'])
                setTimeout(() => this.map.setCenter([this.appRadar['initial_center'].lng, this.appRadar['initial_center'].lat]), 250)
              }
            } else if (!duringMount) {
              if (locationsList?.length === 1) {
                this.map?.setZoom(this.appRadar['single_result_zoom'])
              } else {
                this.map?.setZoom(this.appRadar['multiple_results_zoom'])
              }
              setTimeout(() => this.map.setCenter([locationsList[0].lng, locationsList[0].lat]), 250)
            }
          })
  
          // No locations found
          if (checkNoResults && this.map && locationsList && locationsList.length === 0) {
            if (this.isMobile) {
              const initialZoom = this.appRadar['initial_zoom_mobile'] || this.appRadar['initial_zoom']
              this.map?.setZoom(initialZoom)
            } else {
              this.map?.setZoom(this.appRadar['initial_zoom'])
            }
            
            setTimeout(() => {
              this.map.setCenter([
                this.appRadar['initial_center'].lng,
                this.appRadar['initial_center'].lat,
              ])
            }, 250)
          }
        },
        async setActiveLocation(location) {
          if (location.key) { // get full location information if user hasn't searched
            location = await this.$api.getLocation(location.key, false, false, false)
          } else {
            // Get live location data to replace cached data
            this.setLoading(true)
            const locConfig = {
              method: 'get',
              url: `locations/${location.menu_id}`,
              params: {
                byMenuId: true
              }
            }
            let response = await this.sendRequest(locConfig)
            if (response.status === 'ok') {
              location = response.data
            }
            this.setLoading(false)
          }
          this.activeLocation = location
          setTimeout(() => {
            new maplibregl.Popup({ offset: this.appRadar.offset })
              .setLngLat([location.lng, location.lat])
              .setHTML(document.querySelector('.radar-map-popup').innerHTML)
              .addTo(this.map)
            this.map.flyTo({
              center: [location.lng, location.lat],
              zoom: this.appRadar['single_result_zoom'] || this.appRadar['results_zoom'],
              essential: true,
              duration: 1500
            })
          }, 100)
        },
        async startOrder(loc) {
          if (!loc) return
          if (!loc.services[this.handoffMode] && this.handoffMode !== 'reservation') {
            this.setErrors(`${loc.name} doesn't offer ${this.handoffMode}.`)
            return
          }
  
          console.log('test loc: ', loc)
  
          this.setLocation(loc)
          this.setMenu(null)
          this.trackEvent({
            name: 'select_location',
            data: loc,
            windowObject: window
          })
          if (this.isOpen(loc)) {
            this.$nuxt.$router.push(`/menu/${loc.menu_id}`)
          }
        },
        isOpen(loc) {
          let day = loc.hours.find(d => d.date === moment().format('YYYYMMDD'))
          let now = moment().format('MM/DD/YYYY h:mm a')
          let open = moment(day.open, 'h:mm').format('MM/DD/YYYY h:mm a')
          let close = moment(day.close, 'h:mm').format('MM/DD/YYYY h:mm a')
          return moment(open).isSameOrBefore(moment(now)) && moment(close).isSameOrAfter(moment(now))
        },
        goToReserveTable() {
          this.setHandoffMode('reservation')
          this.setLocation(this.activeLocation)
          this.setMenu(null)
          this.trackEvent({
            name: 'select_location',
            data: this.activeLocation,
            windowObject: window
          })
        },
  
        goToAnnexOrder() {
          this.setHandoffMode('annex')
          this.setLocation(this.activeLocation)
          this.setMenu(null)
        },
  
        getCustomMenuUrl(loc) {
          if (!loc.attributes) return
  
          const urlFound = loc.attributes.find(a => a.id === 'url_menu' && a.value)
          if (!urlFound) return
  
          return urlFound.value
        },
      },
      watch: {
        results() {
          // this.initialize()
          if (this.markersAdded) {
            const checkNoResults = true
            const results = this.results ? this.results : this.locations
            this.addMarkers(results, this.results ? false : true, checkNoResults)
          }
          this.markersAdded = true
        },
        location() {
          if (this.location) {
            this.zoomInLocation()
          } else {
            this.addMarkers(this.locations, true, false)
            this.map?.setZoom(this.appRadar['initial_zoom'])
            setTimeout(() => this.map.setCenter([this.appRadar['initial_center'].lng, this.appRadar['initial_center'].lat]), 250)
          }
        },
        geolocation() {
          if(!this.map || !this.geolocation || this.location) return
          
          this.map?.setZoom(12)
          setTimeout(() => this.map.setCenter([this.geolocation.lng, this.geolocation.lat]), 250)
        }
      },
      onUnmounted() {
        map.value?.remove()
      }
    }
  